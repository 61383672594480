<template>
  <div>
    <v-dialog v-model="dialogreports" persistent fullscreen>
      <v-card >
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div
            v-text="
              trend_type == 'LAST_24_HRS'
                ? `Hourly Data (${new Date(eachDataObject.start_time * 1000).toDateString()},${new Date(eachDataObject.start_time * 1000).toLocaleTimeString()} - ${new Date(
                    (eachDataObject.end_time + 1) * 1000
                  ).toLocaleTimeString()})`
                : `Day Wise Data (${new Date(eachDataObject.start_time * 1000).toDateString()})`
            "
          ></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogreportsEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center px-6">
          <apexchart v-if="overlay == false" height="300px" type="line"  class="my-4" :options="chartOptions" :series="series"></apexchart>
          <v-progress-circular class="mt-16" v-if="overlay == true" indeterminate color="primary" size="120"><div v-text="'Loading ...'"></div></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllTrends } from "@/mixins/GetAllTrends.js";
export default {
  props: {
    GetAllTrendsList: Array,
    dialogreports: Boolean,
    StoreObj: Object,
    trend_type: String,
    tag_id: String,
    tag_description: String,
  },
  mixins: [GetAllTrends],
  data: () => ({
    Toggle: 0,
    overlay: false,
    loading: false,
    series: [
      {
        name: "Values",
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        labels:{
          rotate:-45,
          rotateAlways:false,
          style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          },
        }
      },
      yaxis: {
        title: {
          text: "OMG",
        },
      },
    },
    GetAllRawDataList: [],
    eachDataObject: {},
  }),
  computed: {},
  mounted() {
    this.eachDataObject = this.GetAllTrendsList[this.StoreObj.dataPointIndex];
    if (this.trend_type != "LAST_24_HRS") {
      let time_stamp = this.eachDataObject.start_time * 1000;
      let year = new Date(time_stamp).getFullYear();
      let month = new Date(time_stamp).getMonth();
      let date = new Date(time_stamp).getDate();
      this.eachDataObject.start_time = Math.floor(new Date(year, month, date).getTime() / 1000);
      this.eachDataObject.end_time = Math.floor(new Date(year, month, date, 23, 59, 59).getTime() / 1000);
    }
    this.GetAllTrendsMethod(
      this.trend_type == "LAST_24_HRS" ? "RAW_DATA" : "LAST_24_HRS",
      this.tag_id,
      this.eachDataObject.start_time,
      this.eachDataObject.end_time + 1,
      "SUB_ROUTE",
      undefined,
      this.tag_description
    );
  },
  methods: {
    dialogreportsEmit(Toggle) {
      this.overlay = false;
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
