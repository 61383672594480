<template>
  <div>
    <Overlay :overlay="overlay" />
    <ReportsDialog
      v-if="dialogreports == true"
      :dialogreports="dialogreports"
      :tag_description="tag_description"
      :GetAllTrendsList="GetAllTrendsList"
      :tag_id="tag_id"
      :trend_type="trend_type"
      :StoreObj="StoreObj"
      @clicked="dialogreportsEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Trends</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              dense
              outlined
              label="Trend Type"
              class="field_height field_label_size fontSize maxWidth mt-6 mr-3"
              :items="trendTypeOptions"
              item-text="text"
              item-value="value"
              v-model="trend_type"
            ></v-select>
            <v-menu v-model="menuDatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="current_date"
                  label="Select Date"
                  class="field_height field_label_size fontSize maxWidth mt-6 mr-3"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="current_date" @input="menuDatePicker = false"></v-date-picker>
            </v-menu>
            <v-select
              dense
              outlined
              label="Select OP Parameter"
              class="field_height field_label_size fontSize maxWidthXLarge mt-6"
              :items="GetAllOPParameterList"
              item-text="tag_description"
              item-value="tag_id"
              v-model="tag_id"
            ></v-select>
            <v-btn small class="ml-3 mt-n2" color="primary" @click="callTrendsApI()">Ok</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="pt-4" v-if="overlay == false">
        <apexchart align="left" height="250" type="bar" :options="getChartOptions" :series="series"> </apexchart>
        <apexchart type="line" height="250" :options="trendChartOptions" :series="lineseries"></apexchart>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllTrends } from "@/mixins/GetAllTrends.js";
import { GetAllDashboardParameter } from "@/mixins/GetAllDashboardParameter.js";
import ReportsDialog from "@/components/Reports/Dialogs/ReportsDialog.vue";
export default {
  components: {
    ReportsDialog,
    Overlay,
  },
  mixins: [GetAllDashboardParameter, GetAllTrends],
  data() {
    return {
      tag_description: "",
      current_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      trendTypeOptions: [
        { text: "Last 24 Hrs", value: "LAST_24_HRS" },
        { text: "Last 7 Days", value: "LAST_7_DAYS" },
        { text: "Last 30 days", value: "LAST_30_DAYS" },
      ],
      GetAllTrendsList: [],
      GetAllOPParameterList: [],
      tag_id: "",
      trend_type: "",
      menuDatePicker: false,
      StoreObj: {},
      dialogreports: false,
      series: [
        {
          name: "Minimum",
          data: [],
        },
        {
          name: "Maximum",
          data: [],
        },
        {
          name: "Average",
          data: [],
        },
      ],
      lineseries: [
        {
          name: "Values",
          data: [],
        },
      ],
      trendChartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 450,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  computed: {
    getChartOptions() {
      var self = this;
      let chartOptions = {
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          height: 350,
          events: {
            click: function (event, chartContext, config) {
              self.saveIndexMethod(config);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Values",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {},
      };
      return chartOptions;
    },
  },
  watch: {
    GetAllDashboardParameterList(val) {
      this.overlay = false;
      if (val && val.length != 0) {
        this.tag_id = val[0].tag_id;
        this.trend_type = "LAST_24_HRS";
        this.GetAllOPParameterList = this.GetAllDashboardParameterList.filter((parameter) => parameter.type == "OPERATIONAL_PARAMETER");
        this.tag_description = this.GetAllOPParameterList.filter((tag) => tag.tag_id == this.tag_id)[0].tag_description;
        console.log("Check tag_description", this.tag_description);
        this.GetAllTrendsMethod(
          this.trend_type,
          this.tag_id,
          undefined,
          undefined,
          "MAIN_ROUTE",
          this.current_date,
          this.GetAllOPParameterList.filter((tag) => tag.tag_id == this.tag_id)[0].tag_description
        );
      }
    },
  },
  mounted() {
    this.GetAllDashboardParameterMethod(this.$store.getters.get_asset_object.asset_id);
  },
  methods: {
    callTrendsApI() {
      if (this.trend_type && this.trend_type != "" && this.tag_id && this.tag_id != "") {
        this.tag_description = this.GetAllOPParameterList.filter((tag) => tag.tag_id == this.tag_id)[0].tag_description;
        this.GetAllTrendsMethod(
          this.trend_type,
          this.tag_id,
          undefined,
          undefined,
          "MAIN_ROUTE",
          this.current_date,
          this.GetAllOPParameterList.filter((tag) => tag.tag_id == this.tag_id)[0].tag_description
        );
      }
    },
    dialogreportsEmit() {
      this.dialogreports = false;
    },
    saveIndexMethod(config) {
      if (config.dataPointIndex != -1) {
        this.dialogreports = true;
        this.StoreObj = config;
      }
    },
  },
};
</script>
