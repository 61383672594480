import { API } from "aws-amplify";
export const GetAllTrends = {
  data() {
    return {};
  },
  methods: {
    getTimeStamp(trend_type, current_date, start_or_end) {
      switch (start_or_end) {
        case "START":
          return (
            Math.floor(new Date(Number(current_date.split("-")[0]), Number(current_date.split("-")[1]) - 1, Number(current_date.split("-")[2]), 0, 0, 0).getTime() / 1000) -
            86400 * (trend_type == "LAST_24_HRS" ? 1 : trend_type == "LAST_7_DAYS" ? 7 : 30)
          );
        case "END":
          return Math.floor(new Date(Number(current_date.split("-")[0]), Number(current_date.split("-")[1]) - 1, Number(current_date.split("-")[2]), 0, 0, 0).getTime() / 1000);
      }
    },
    async GetAllTrendsMethod(trend_type, tag_id, start_timestamp, end_timestamp, route_type, current_date, tag_description) {
      console.log(current_date);
      var self = this;
      if (trend_type != "RAW_DATA") {
        self.GetAllTrendsList = [];
      }
      self.overlay = true;
      const path = "gettrendsforoperationalparameters";
      const myInit = {
        body: {
          command: "generateTrendsReport",
          organization_id: this.$store.getters.get_current_user_details.organization_id,
          asset_id: this.$store.getters.get_asset_object.asset_id,
          tag_id,
          trend_type: trend_type,
          start_timestamp: route_type == "MAIN_ROUTE" ? this.getTimeStamp(trend_type, current_date, "START") : start_timestamp,
          end_timestamp: route_type == "MAIN_ROUTE" ? this.getTimeStamp(trend_type, current_date, "END") : end_timestamp,
        },
      };
      console.log("Check myInit", myInit);
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          self.overlay = false;
          if (!response.errorType) {
            if (trend_type != "RAW_DATA") {
              this.GetAllTrendsList = response;
            } else {
              this.GetAllRawDataList = response;
            }

            switch (trend_type) {
              case "LAST_24_HRS":
                if (route_type == "MAIN_ROUTE") {
                  this.getChartOptions.xaxis.categories = response.map(
                    (option) => `${new Date((option.end_time + 1) * 1000).toLocaleTimeString().split(":")[0]} ${new Date((option.end_time + 1) * 1000).toLocaleTimeString().split(" ")[1]}`
                  );
                  this.getChartOptions.yaxis.title.text = `${tag_description}`;
                  this.series[0].data = response.map((option) => option.minimum_value);
                  this.series[1].data = response.map((option) => option.maximum_value);
                  this.series[2].data = response.map((option) => option.average_value);
                  this.trendChartOptions.xaxis.categories = response.map(
                    (option) => `${new Date((option.end_time + 1) * 1000).toLocaleTimeString().split(":")[0]} ${new Date((option.end_time + 1) * 1000).toLocaleTimeString().split(" ")[1]}`
                  );
                  this.lineseries[0].data = response.map((option) => option.average_value);
                } else if (route_type == "SUB_ROUTE") {
                  this.chartOptions.yaxis.title.text = `${tag_description}`;
                  this.chartOptions.xaxis.categories = response.map((option) => `${new Date((option.end_time + 1) * 1000).toLocaleTimeString()}`);
                  this.series[0].data = response.map((option) => option.average_value);
                }

                this.$forceUpdate();
                break;
              case "LAST_7_DAYS":
                this.getChartOptions.yaxis.title.text = `${tag_description}`;
                this.getChartOptions.xaxis.categories = response.map((option) => `${new Date((option.end_time + 1) * 1000).toLocaleDateString()}`);
                this.series[0].data = response.map((option) => option.minimum_value);
                this.series[1].data = response.map((option) => option.maximum_value);
                this.series[2].data = response.map((option) => option.average_value);
                this.trendChartOptions.xaxis.categories = response.map((option) => `${new Date((option.end_time + 1) * 1000).toLocaleDateString()}`);
                this.lineseries[0].data = response.map((option) => option.average_value);
                this.$forceUpdate();
                break;
              case "LAST_30_DAYS":
                this.getChartOptions.yaxis.title.text = `${tag_description}`;
                this.getChartOptions.xaxis.categories = response.map((option) => `${new Date((option.end_time + 1) * 1000).toLocaleDateString().split(",")[0]}`);
                this.series[0].data = response.map((option) => option.minimum_value);
                this.series[1].data = response.map((option) => option.maximum_value);
                this.series[2].data = response.map((option) => option.average_value);
                this.trendChartOptions.xaxis.categories = response.map((option) => `${new Date((option.end_time + 1) * 1000).toLocaleDateString().split(",")[0]}`);
                this.lineseries[0].data = response.map((option) => option.average_value);
                this.$forceUpdate();
                break;
              case "RAW_DATA":
                this.chartOptions.yaxis.title.text = `${tag_description}`;
                this.chartOptions.xaxis.categories = response.map((option, idx) => (idx % 50 == 0 ? `${new Date(option.kpch_tist * 1000).toLocaleTimeString().split(":")[0]}:${new Date(option.kpch_tist * 1000).toLocaleTimeString().split(":")[1]} ${new Date(option.kpch_tist * 1000).toLocaleTimeString().split(":")[2].split(" ")[1]}` : ""));
                this.series[0].data = response.map((option) => option.kpch_val);
                this.$forceUpdate();
                break;
            }
          } else {
            this.errorMethod(trend_type, tag_id, start_timestamp, end_timestamp, route_type, current_date, tag_description);
          }
        })
        .catch((error) => {
          console.log("Check The Error", error);
          self.overlay = false;
          this.errorMethod(trend_type, tag_id, start_timestamp, end_timestamp, route_type, current_date, tag_description);
        });
    },
    errorMethod(trend_type, tag_id, start_timestamp, end_timestamp, route_type, current_date, tag_description) {
      switch (trend_type) {
        case "LAST_24_HRS":
          if (route_type == "MAIN_ROUTE") {
            this.getChartOptions.xaxis.categories = [];
            this.getChartOptions.yaxis.title.text = `${tag_description}`;
            this.series[0].data = [];
            this.series[1].data = [];
            this.series[2].data = [];
            this.trendChartOptions.xaxis.categories = [];
            this.lineseries[0].data = [];
          } else if (route_type == "SUB_ROUTE") {
            this.chartOptions.yaxis.title.text = `${tag_description}`;
            this.chartOptions.xaxis.categories = [];
          }

          this.$forceUpdate();
          break;
        case "LAST_7_DAYS":
          this.getChartOptions.yaxis.title.text = `${tag_description}`;
          this.getChartOptions.xaxis.categories = [];
          this.series[0].data = [];
          this.series[1].data = [];
          this.series[2].data = [];
          this.trendChartOptions.xaxis.categories = [];
          this.lineseries[0].data = [];
          this.$forceUpdate();
          break;
        case "LAST_30_DAYS":
          this.getChartOptions.yaxis.title.text = `${tag_description}`;
          this.getChartOptions.xaxis.categories = [];
          this.series[0].data = [];
          this.series[1].data = [];
          this.series[2].data = [];
          this.trendChartOptions.xaxis.categories = [];
          this.lineseries[0].data = [];
          this.$forceUpdate();
          break;
        case "RAW_DATA":
          this.chartOptions.yaxis.title.text = `${tag_description}`;
          this.chartOptions.xaxis.categories = [];
          this.series[0].data = [];
          this.$forceUpdate();
          break;
      }
    },
  },
};
